
import React, { useState, useEffect, useCallback } from "react";
import { Page, Text, View, Document, StyleSheet, pdf, Image } from "@react-pdf/renderer";
import { DownloadSimple } from "phosphor-react";

import { TalentDetails } from "../../models/app/talent";
import formatDateOnlyString from "../../utils/formatDateString";

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: 30,
    },
    section: {
        marginBottom: 20,
    },
    heading: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 10,
        color: "#1F2937",
    },
    subheading: {
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 5,
        color: "#374151",
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
        color: "#4B5563",
    },
    tag: {
        backgroundColor: "#E5E7EB",
        borderRadius: 4,
        padding: "4 8",
        marginRight: 5,
        marginBottom: 5,
        display: "flex",
    },
    tagText: {
        fontSize: 10,
        color: "#374151",
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 10,
    },
    profileImage: {
        width: 100,
        height: 100,
        borderRadius: 50,
        marginBottom: 10,
    },
});

interface TalentProfilePdfProps {
    talent: TalentDetails;
}

const TalentProfilePdf: React.FC<TalentProfilePdfProps> = ({ 
    talent 
}) => {
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [profileImageUrl, setProfileImageUrl] = useState<string>();

    const base64ToUint8Array = (base64: string): Uint8Array => {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    };

    useEffect(() => {
        if (talent.profileImageBase64Content) {
            const base64Content = talent.profileImageBase64Content.split(",")[1] || talent.profileImageBase64Content;
            const byteArray = base64ToUint8Array(base64Content);
            const blob = new Blob([byteArray], { type: "image/jpeg" });
            const url = URL.createObjectURL(blob);
            setProfileImageUrl(url);
        }
    }, [talent.profileImageBase64Content]);

    const generatePdf = useCallback(async () => {
        try {
            const MyDoc = (
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                            {profileImageUrl && (
                                <Image src={profileImageUrl} style={styles.profileImage} />
                            )}
                            <Text style={styles.heading}>{talent.fullName}</Text>
                            {talent.tradingName && <Text style={styles.subheading}>{talent.tradingName}</Text>}
                        </View>

                        {talent.aboutMe && (
                            <View style={styles.section}>
                                <Text style={styles.subheading}>Business Summary</Text>
                                <Text style={styles.text}>{talent.aboutMe}</Text>
                            </View>
                        )}

                        <View style={styles.section}>
                            <Text style={styles.subheading}>Location</Text>
                            <Text style={styles.text}>
                                {`${talent.location?.locality?.locality}${talent.location?.locality?.region ? ` (${talent.location?.locality.region})` : ""}, ${talent.location?.country}`}
                            </Text>
                        </View>

                        {talent.wheelPosition && (
                            <View style={styles.section}>
                                <Text style={styles.subheading}>Wheel Position</Text>
                                <View style={{
                                    flexDirection: "row",
                                    borderRadius: 4,
                                    overflow: "hidden"
                                }}>
                                    <View style={{
                                        backgroundColor: talent.wheelPosition.primaryBgColor,
                                        padding: 5
                                    }}>
                                        <Text style={{
                                            color: talent.wheelPosition.primaryTextColor,
                                            fontSize: 10
                                        }}>
                                            {talent.wheelPosition.wheelPosition}
                                        </Text>
                                    </View>
                                    {talent.wheelPosition.secondary && (
                                        <View style={{
                                            backgroundColor: talent.wheelPosition.secondaryBgColor,
                                            padding: 5,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4
                                        }}>
                                            <Text style={{
                                                color: talent.wheelPosition.secondaryTextColor,
                                                fontSize: 10
                                            }}>
                                                {talent.wheelPosition.secondary}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                            </View>
                        )}

                        {talent.skills && talent.skills.length > 0 && (
                            <View style={styles.section}>
                                <Text style={styles.subheading}>Insights Workshop Types</Text>
                                <View style={styles.row}>
                                    {talent.skills.map((skill, index) => (
                                        <View key={index} style={styles.tag}>
                                            <Text style={styles.tagText}>{skill.skillName}</Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        )}

                        {talent.languages && talent.languages.length > 0 && (
                            <View style={styles.section}>
                                <Text style={styles.subheading}>Languages</Text>
                                <View style={styles.row}>
                                    {talent.languages.map((language, index) => (
                                        <View key={index} style={styles.tag}>
                                            <Text style={styles.tagText}>
                                                {`${language.languageName} (${language.languageProficiencyName})`}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        )}

                        {talent.deliveryTypes && talent.deliveryTypes.length > 0 && (
                            <View style={styles.section}>
                                <Text style={styles.subheading}>Delivery Types</Text>
                                <View style={styles.row}>
                                    {talent.deliveryTypes.map((type, index) => (
                                        <View key={index} style={styles.tag}>
                                            <Text style={styles.tagText}>{type.deliveryTypeName}</Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        )}

                        {talent.industrialExperiences && talent.industrialExperiences.length > 0 && (
                            <View style={styles.section}>
                                <Text style={styles.subheading}>Industry Experience</Text>
                                <View style={styles.row}>
                                    {talent.industrialExperiences.map((industry, index) => (
                                        <View key={index} style={styles.tag}>
                                            <Text style={styles.tagText}>{industry.industrialExperienceName}</Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        )}

                        {talent.certifications && talent.certifications.length > 0 && (
                            <View style={styles.section}>
                                <Text style={styles.subheading}>Certifications</Text>
                                <View style={styles.row}>
                                    {talent.certifications.map((cert, index) => (
                                        <View key={index} style={styles.tag}>
                                            <Text style={styles.tagText}>
                                                {cert.title} ({cert.achievementDate ? formatDateOnlyString(cert.achievementDate.toString()) : "N/A"})
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        )}

                    </Page>
                </Document>
            );

            const blob = await pdf(MyDoc).toBlob();
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
        } catch (err) {
            console.error("Error generating PDF:", err);
            setError("Error generating PDF. Please try again later.");
        }
    }, [talent, profileImageUrl]);

    useEffect(() => {
        generatePdf();
    }, [generatePdf]);

    useEffect(() => {
        return () => {
            if (profileImageUrl) {
                URL.revokeObjectURL(profileImageUrl);
            }
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [profileImageUrl, pdfUrl]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!pdfUrl) {
        return <div>Loading PDF...</div>;
    }

    return (
        <a href={pdfUrl} download={`${talent.fullName.replace(/\s+/g, "_")}_profile.pdf`}>
            <DownloadSimple size={24} />
        </a>
    );
};

export default TalentProfilePdf;
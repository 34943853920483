import { useEffect } from "react";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import Card from "../../components/Card";
import FormAccordion from "../../components/FormAccordion";
import InfoBox from "../../components/InfoBox";
import PageContent from "../../components/PageContent";
import Certifications from "./Certifications";
import TalentIndustrialExperienceForm from "./TalentIndustrialExperienceForm";
import TalentPersonalDetailsForm from "./TalentPersonalDetailsForm";
import TalentSkillsForm from "./TalentSkillsForm";
import TalentLanguageForm from "./TalentLanguageForm";
import TalentDeliveryTypeForm from "./TalentDeliveryTypeForm";

const TalentProfileEditPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { talentPersonalInformation } = useTalentPersonalInformationContext();


    return (
        <PageContent className="child:mx-0">
            {talentPersonalInformation && !talentPersonalInformation.profileIsComplete && (
                <InfoBox className="mb-4" variant="info" RootElement="div">
                    Please ensure all required fields are completed and kept up to date:
                    <ul className="list-disc ml-8">
                        <li>First name</li>
                        <li>Last name</li>
                        <li>Company name</li>
                        <li>Location (Country & City)</li>
                        <li>Insights workshop type experience</li>
                        <li>Delivery types</li>
                        <li>Languages</li>
                    </ul>
                </InfoBox>
            )}
            <Card className="rounded-none sm:rounded-lg">
                <FormAccordion
                    steps={[
                        {
                            title: "Your details",
                            content: (
                                <TalentPersonalDetailsForm />
                            )
                        },
                        {
                            title: "Your workshop types",
                            content: (
                                <>
                                    <div className="space-y-12">
                                        <TalentSkillsForm />
                                    </div>
                                </>
                            ),
                        },
                        {
                            title: "Languages",
                            content: (
                                <>
                                    <div className="space-y-12">
                                        <TalentLanguageForm />
                                    </div>
                                </>
                            ),
                        },
                        {
                            title: "Delivery types",
                            content: (
                                <>
                                    <p className="mb-8">What delivery types are you comfortable with?</p>
                                    <TalentDeliveryTypeForm />
                                </>
                            ),
                        },
                        {
                            title: "Industry experience",
                            content: (
                                <>
                                    <div className="space-y-12">
                                        <TalentIndustrialExperienceForm />
                                    </div>
                                </>
                            ),
                        },
                        {
                            title: "Documents",
                            content: (
                                <>
                                    <p className="mb-8">Add any relevant documents here. </p>
                                    <div className="space-y-12">
                                        <Certifications />
                                    </div>
                                </>
                            ),
                        }
                    ]}
                />
            </Card>
        </PageContent>
    );
};

export default TalentProfileEditPage;